<template>
  <fb-page>
    <div class="loading" v-if="load">
      <van-loading color="black" />
    </div>
    <div class="sea_title">
      <ul class="sea_icon" v-if="search_list">
        <div style="display:flex;margin-left:0.5rem">
          <li @click="back">
            <i class="iconfont icon-back"></i>
          </li>
          <li style="margin-left:0.3rem">常用文书</li>
        </div>
        <div>
          <li style="margin-right:0.5rem" @click="polit">
            <i class="iconfont icon-search"></i>
          </li>
        </div>
      </ul>
      <ul class="sea_input" v-else>
        <li>
          <i class="iconfont icon-search"></i>
        </li>
        <li>
          <input
            type="text"
            v-model="caseinput"
            ref="case_input"
            @focus="fuinput"
            @blur="blnput"
          />
        </li>
      </ul>
    </div>
    <div class="document-type-list">
      <div
        class="document-type"
        v-for="(item, i) in casealloneList"
        :key="i"
        @click="setCaseallone(item)"
        :style="
          item.val === caseallone
            ? 'border-bottom:5px solid #1989FA;color:#1989FA'
            : ''
        "
      >
        {{ item.name }}
      </div>
    </div>
    <div id="my-document-page">
      <!-- <div class="function" v-if="isshowtitle">
        <van-checkbox
          shape="square"
          @change="checkallhandle"
          v-model="call"
        ></van-checkbox>
        <span v-if="!ischecked">名称</span>
        <div class="fun" v-if="ischecked">
          <span>打印</span>
          <span>下载</span>-->
      <!-- <span @click='deletedocument'>删除</span> -->
      <!-- </div>
      </div>  -->
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-checkbox-group v-model="result" @change="choosechecked">
          <div class="checkbox">
            <van-cell v-for="(item, i) in documentList" :key="i">
              <!-- <van-checkbox
                v-for="(item, i) in documentList"
                :key="i"
                :name="item"
                shape="square"
              > -->
              <div
                class="intro"
                @click.stop="goEditorPage(item)"
                style="font-size:102%"
              >
                {{ item.documentName }}
              </div>
              <!-- </van-checkbox> -->
            </van-cell>
          </div>
        </van-checkbox-group>
      </van-list>
    </div>
  </fb-page>
</template>
<script>
import { caseBase } from '~api-config'

export default {
  name: 'litigation-document',
  filters: {
    // 开始时间格式化   年：月：日  时：分
    DateTime: function(value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      const hours = beginTime.getHours()
      const minutes = beginTime.getMinutes()
      return (
        year +
        '.' +
        (month > 9 ? month : '0' + month) +
        '.' +
        (day > 9 ? day : '0' + day) +
        ' ' +
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes)
      )
    },
    // 年：月：日
    GetDate: function(value) {
      const beginTime = new Date(value)
      const year = beginTime.getFullYear()
      const month = beginTime.getMonth() + 1
      const day = beginTime.getDate()
      return (
        year +
        '.' +
        (month > 9 ? month : '0' + month) +
        '.' +
        (day > 9 ? day : '0' + day)
      )
    },
    NumberFormat: function(value) {
      return (value * 100).toFixed(2) + '%'
    }
  },
  data() {
    return {
      caseallone: 1,
      casealloneList: [
        {
          name: '民事一审',
          val: 1
        },
        {
          name: '民事二审',
          val: 2
        },
        {
          name: '行政一审',
          val: 3
        },
        {
          name: '刑事一审',
          val: 4
        },
        {
          name: '刑事二审',
          val: 5
        }
      ],
      caseinput: '',
      search_list: true,
      call: false,
      result: [],
      ss: false,
      inputsearch: '',
      isshowsearch: false,
      documentList: [],
      num: 0,
      checked: [],
      checkedlist: [],
      ischecked: false,
      isallchecked: false,
      loading: false,
      finished: false,
      list: [],
      ls: [],
      number: 0,
      deletelist: [],
      isdelete: false,
      doclist: [],
      isshowtitle: true,
      load: false,
      arr: [],
      is: false
    }
  },
  watch: {
    caseinput(val) {
      setTimeout(() => {
        this.num = 0
        this.documentList = []
        this.onLoad()
      }, 600)
    }
  },
  methods: {
    back() {
      window.goback()
    },
    setCaseallone(item) {
      this.caseallone = item.val
      setTimeout(() => {
        this.finished = false
        this.num = 0
        this.documentList = []
        this.onLoad()
      }, 600)
    },
    polit() {
      this.search_list = false
      setTimeout(() => {
        this.$refs.case_input.focus()
        if (this.caseinput) {
          this.$refs.case_input.select()
        }
      })
    },
    fuinput() {},
    blnput() {
      setTimeout(() => {
        this.search_list = true
      })
    },
    // 单选
    choosechecked(v) {
      // console.log(this.result)
      // if (this.result.length === 0) {
      //   this.ischecked = false
      // } else {
      //   this.ischecked = true
      // }
      this.is = true

      if (this.result.length !== this.documentList.length) {
        console.log(13456)
        if (this.result.length === 0) {
          this.ischecked = false
        } else {
          this.ischecked = true
        }
        this.fun(false, 2)
      } else {
        this.fun(true, 2)
      }
    },
    onLoad() {
      this.finished = true

      // 异步更新数据
      // setTimeout(() => {
      const params = {
        limit: 20,
        pageNum: this.num,
        documentName: this.caseinput
      }
      this.num++
      let url = ''
      console.log('***********************************************1')
      switch (this.caseallone) {
        case 1:
          url = `${caseBase}/document/basicDocuments/likeFrequentlyUsedDoc?firstCaseReasonValueId=10009&procedureTypeValueId=10014`
          break
        case 2:
          url = `${caseBase}/document/basicDocuments/likeFrequentlyUsedDoc?firstCaseReasonValueId=10009&procedureTypeValueId=10015`
          break
        case 3:
          url = `${caseBase}/document/basicDocuments/likeFrequentlyUsedDoc?firstCaseReasonValueId=10011&procedureTypeValueId=10014`
          break
        case 4:
          url = `${caseBase}/document/basicDocuments/likeFrequentlyUsedDoc?firstCaseReasonValueId=10010&procedureTypeValueId=10014`
          break
        case 5:
          url = `${caseBase}/document/basicDocuments/likeFrequentlyUsedDoc?firstCaseReasonValueId=10010&procedureTypeValueId=10015`
          break
      }
      this.$axios
        .get(url, {
          params
        })
        .then(res => {
          console.log(res)
          if (res.data.code === '200') {
            // 加载状态结束
            // this.loading = false
            // this.loading = true

            if (this.isdelete) {
              this.documentList = []
              this.number = 0

              for (const i in res.data.data.items) {
                this.documentList.push(res.data.data.items[i])
                this.checked[i] = false
                this.checkedlist.push(this.checked[i])
              }
            } else {
              for (const i in res.data.data.items) {
                if (this.isallchecked) {
                  this.checked[i] = true
                  this.number++
                  this.list.push(res.data.data.items[i])
                } else {
                  this.checked[i] = false
                }
                this.documentList.push(res.data.data.items[i])
                this.checkedlist.push(this.checked[i])
                this.ls = this.checkedlist
              }
            }
            this.doclist = res.data.data.items

            // 数据全部加载完成

            if (this.documentList.length >= res.data.data.total) {
              this.finished = true
            } else {
              this.finished = false
            }
            if (res.data.data.items.length === 0) {
              this.finished = true
            }
          }
          this.loading = false
        })

      // }, 500)
    },

    // 进入编辑器页面
    goEditorPage(val) {
      this.load = true
      this.$axios
        .get(`${caseBase}/lts/case/defaultCreateCase`)
        .then(res => {
          this.load = false
          // console.log(res)
          if (res.data.code === '1003') {
            this.$router.push({
              path: '/lawyerAuth'
            })
            return
          }
          if (res.data.data) {
            const caseId = res.data.data
            const path = `/editor/${caseId}?caseDocumentId=${
              val.documentId
            }&groupId=${val.groupId || ''}`
            this.$router.push(path)
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    },

    fun(bool, type) {
      if (type === 1 && !this.is) {
        if (bool) {
          this.result = this.documentList
          this.call = bool
          this.ischecked = true
        } else {
          this.result = []
          this.call = bool
          this.ischecked = false
        }
      }
      if (type === 2) {
        this.call = bool
        setTimeout(() => {
          this.is = false
        })
      }
    },
    // 全选/全不选
    checkallhandle(v) {
      this.fun(v, 1)
    },
    // 删除
    deletedocument() {
      this.deletelist = []
      for (const i of this.result) {
        this.deletelist.push(i.documentId)
      }

      console.log(this.deletelist)
      this.$axios
        .post(
          `${caseBase}/document/basicCatalog/deleteMyDocument?myDocumentIdList=${
            this.deletelist
          }`
        )
        .then(res => {
          console.log(res)
          if (res.data.code === '200') {
            location.reload()
            // this.documentList = []
            // this.onLoad()
          }
        })
    },
    // 输入
    inputvalue() {
      // console.log(this.inputsearch)
      this.$axios
        .get(
          `${caseBase}/document/basicDocuments/getDocByName?name=${
            this.inputsearch
          }`
        )
        .then(res => {
          // console.log('======------', res)
          this.documentList = res.data.data.recentDocPage.items
          if (this.documentList.length === 0) {
            this.isshowtitle = false
          } else {
            this.isshowtitle = true
          }
        })
    },
    sh() {
      this.isshowsearch = !this.isshowsearch
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    // 输入框失焦返回搜索按钮
    gobutton() {
      if (this.inputsearch === '') {
        this.isshowsearch = false
      }
    },
    goback() {
      history.go(-1)
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.caseallone = parseInt(this.$route.query.type)
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/.main-wrapper
  padding-top 0
.icon-kongbai, .icon-xuanzhong
  font-size 0.5rem
  margin-right 0.2rem
.icon-xuanzhong
  color #4B9EFB
.loading
  width 100%
  height 100%
  position absolute
  // text-align center
  display flex
  // align-items center
  z-index 300
  background whitesmoke
  opacity 0.6
  .van-loading
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
.sea_title
  width 100%
  height 41px
  border-bottom 1px solid rgba(243, 244, 247, 1)
  position relative
  .sea_icon
    display flex
    justify-content space-between
    line-height 41px
    font-size 14px
  .sea_input
    width 92%
    height 26px
    line-height 26px
    background rgba(245, 245, 245, 1)
    border-radius 13px
    position absolute
    top calc(50% - 13px)
    margin-left 0.5rem
    display flex
    li:nth-child(1)
      width 8%
      margin-left 10px
    li:nth-child(2)
      width 90%
      height 95%
      input
        width 100%
        height 95%
        background rgba(245, 245, 245, 1)
        border-radius 13px
        border none
        outline none
.document-type-list
  width 100%
  display flex
  flex-direction row
  align-items center
  padding 10px 0.2rem 10px 0.5rem
  border-bottom 1px solid #CCC
  & .document-type
    margin-right 10px
#my-document-page
  width 100%
  .function
    display flex
    align-items center
    margin-top 0.3rem
    /deep/ .van-checkbox
      margin-left 0.5rem
      margin-right 0.2rem
    .headicon
      margin-left 0.4rem
    .fun
      width 30%
      display flex
      justify-content space-between
    span
      font-size 0.4rem
  .checkbox
    /deep/ .van-checkbox
      display flex
      align-items center
      padding 0 0.1rem
      margin-top 0.2rem
      border-bottom 1px solid #ccc
      height 0.9rem
      font-weight 900
      /deep/ .van-checkbox__label
        display flex
        width 100%
        .intro
          width 98%
          font-size 102%
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
</style>
