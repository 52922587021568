var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fb-page", [
    _vm.load
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("van-loading", { attrs: { color: "black" } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "sea_title" }, [
      _vm.search_list
        ? _c("ul", { staticClass: "sea_icon" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-left": "0.5rem" } },
              [
                _c("li", { on: { click: _vm.back } }, [
                  _c("i", { staticClass: "iconfont icon-back" }),
                ]),
                _c("li", { staticStyle: { "margin-left": "0.3rem" } }, [
                  _vm._v("常用文书"),
                ]),
              ]
            ),
            _c("div", [
              _c(
                "li",
                {
                  staticStyle: { "margin-right": "0.5rem" },
                  on: { click: _vm.polit },
                },
                [_c("i", { staticClass: "iconfont icon-search" })]
              ),
            ]),
          ])
        : _c("ul", { staticClass: "sea_input" }, [
            _c("li", [_c("i", { staticClass: "iconfont icon-search" })]),
            _c("li", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.caseinput,
                    expression: "caseinput",
                  },
                ],
                ref: "case_input",
                attrs: { type: "text" },
                domProps: { value: _vm.caseinput },
                on: {
                  focus: _vm.fuinput,
                  blur: _vm.blnput,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.caseinput = $event.target.value
                  },
                },
              }),
            ]),
          ]),
    ]),
    _c(
      "div",
      { staticClass: "document-type-list" },
      _vm._l(_vm.casealloneList, function (item, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "document-type",
            style:
              item.val === _vm.caseallone
                ? "border-bottom:5px solid #1989FA;color:#1989FA"
                : "",
            on: {
              click: function ($event) {
                return _vm.setCaseallone(item)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { attrs: { id: "my-document-page" } },
      [
        _c(
          "van-list",
          {
            attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
            on: { load: _vm.onLoad },
            model: {
              value: _vm.loading,
              callback: function ($$v) {
                _vm.loading = $$v
              },
              expression: "loading",
            },
          },
          [
            _c(
              "van-checkbox-group",
              {
                on: { change: _vm.choosechecked },
                model: {
                  value: _vm.result,
                  callback: function ($$v) {
                    _vm.result = $$v
                  },
                  expression: "result",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  _vm._l(_vm.documentList, function (item, i) {
                    return _c("van-cell", { key: i }, [
                      _c(
                        "div",
                        {
                          staticClass: "intro",
                          staticStyle: { "font-size": "102%" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.goEditorPage(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.documentName) + " ")]
                      ),
                    ])
                  }),
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }